import React from 'react'

import { graphql } from 'gatsby'

import ArticlePage from '../containers/ArticlePage'


// =============================================================================
const BlogPost = ({ data }) => {
  const { mdx: post } = data
  const { uid, draft, frontmatter } = post

  return (
    <ArticlePage
      articleId={uid} title={frontmatter.title} date={frontmatter.date}
      isDraft={draft} timeToRead={post.timeToRead} tags={frontmatter.tags}
      code={post.body}
    />
  )
}

// -----------------------------------------------------------------------------
export default BlogPost


// =============================================================================
export const query = graphql`
  query BlogPostQuery($path: String!) {
    mdx(path: { eq: $path }) {
      uid
      draft
      body
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`
