import React from 'react'
import PropTypes from 'prop-types'

import { StaticQuery, graphql } from 'gatsby'

import { MDXRenderer } from 'gatsby-plugin-mdx'

import { DiscussionEmbed } from 'disqus-react'

import 'prismjs/themes/prism.css'
import 'prismjs/themes/prism-twilight.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'

import ArticleTitleBlock from '../components/ArticleTitleBlock'
import Page from './Page'

import './ArticlePage.scss'


// =============================================================================
const ArticlePage = (props) => (
  <Page
    title={props.title}
    educateChildren={false}
    headerComponent={
      ({ title }) => <ArticleTitleBlock {...props} title={title} />}
  >
    <article className="Article">
      <main>
        <MDXRenderer>{props.code}</MDXRenderer>
      </main>
      <StaticQuery
        query={graphql`
          query ArticleQuery {
            site {
              siteMetadata {
                disqusShortname
              }
            }
          }
        `}
        render={data => {
          const shortname = data.site.siteMetadata.disqusShortname
          const config = {
            identifier: props.articleId,
            title: props.title,
          }

          return <DiscussionEmbed shortname={shortname} config={config} />
        }}
      />
    </article>
  </Page>
)

// -----------------------------------------------------------------------------
ArticlePage.propTypes = {
  articleId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  timeToRead: PropTypes.string.isRequired,
  code: PropTypes.node.isRequired,
}

// -----------------------------------------------------------------------------
export default ArticlePage
